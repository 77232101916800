import React from 'react'
import Layout from 'components/layout'
import {navigate, graphql, Link} from 'gatsby'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import readGif from '../assets/images/home/READ.gif'
import {useState} from 'react'
import VisuallyHidden from '@reach/visually-hidden'
import Play from '../assets/images/play.inline.svg'
import {localizedPaths} from 'utils'

export default function IndexPage(props) {
  const [loadVideo, setLoadVideo] = useState(false)

  const page = {
    title: 'Home',
    id: 'home',
  }

  const {tools, introBg, oliveBg, video} = props.data

  // GROSS HACK mutate pageContext
  props.pageContext.lang = 'en'
  props.pageContext.localizedPaths = localizedPaths

  return (
    <Layout pageInfo={page} {...props}>
      <div className="absolute -top-1 left-0 w-full h-full -z-20 bg-orange">
        <GatsbyImage
          image={introBg.childImageSharp.gatsbyImageData}
          loading="eager"
          style={{position: 'fixed'}}
          className="inset-0 w-full"
          objectPosition="20% 50%"
          alt=""
        />
      </div>
      <section className="relative flex min-h-screen overflow-auto text-white">
        <div className="max-w-4xl mx-auto px-6 flex flex-col justify-center py-24">
          <h1 className="mb-3 sm:text-5xl">
            {"We're building a platform for revolutionary autonomy."}
          </h1>
          <p className="font-sans sm:text-4xl text-2xl lg:leading-normal">
            Amid climate change and economic ruin, we’re connecting a network of
            people who are ready to live and fight, aggregate skills, build
            infrastructure, and share tools to make a different world.
          </p>
        </div>
      </section>
      <div className="bg-orange relative py-8 flex justify-around text-xs sm:text-xl lg:text-2xl text-white uppercase text-center">
        {/* eslint-disable prettier/prettier */}
        <p className="mb-0">
          There are
          <br />
          two paths
        </p>
        <p className="mb-0">
          The end
          <br />
          of the world
        </p>
        <p className="mb-0">
          Or the
          <br />
          beginning
        </p>
        <p className="mb-0">
          Of the
          <br />
          next
        </p>
        {/* eslint-enable prettier/prettier */}
      </div>
      <section className="relative flex justify-center items-center overflow-hidden">
        <p className="z-10 font-display text-center text-white mx-auto mb-0 leading-none text-4xl max-w-xl px-6 py-24 xl:text-5xl xl:py-46">
          Inhabit is a book,
          <br />
          an online portal,
          <br />
          and an IRL network for
          <br />
          our critical&nbsp;time.
        </p>
        <GatsbyImage
          alt=""
          image={oliveBg.childImageSharp.gatsbyImageData}
          style={{position: 'absolute'}}
          className="-inset-x-40 md:inset-x-0 bottom-0 z-0"
        />
      </section>
      <section className="text-center bg-white overflow-auto">
        <img src={readGif} alt="" className="mx-auto my-12" />
        <Link to="/read" className="btn-orange">
          Read the book
        </Link>
        <div className=" text-olive text-xl mt-4">
          Translations:
          <br />
          {Object.keys(localizedPaths)
            .filter((path) => path !== 'en')
            .map((path) => {
              return (
                <Link
                  key={path}
                  className="underline px-1 text-md hover:text-orange"
                  to={localizedPaths[path].read}
                >
                  {localizedPaths[path].label}
                </Link>
              )
            })}
        </div>
      </section>
      <section className="pt-32 bg-white">
        <h2 className="text-outline-orange font-extended uppercase text-center mb-12 grid grid-rows-2 md:grid-rows-1 gap-x-12 md:gap-0 md:grid-cols-2 max-w-5xl mx-auto text-3xl sm:text-4vw lg:text-3vw">
          <span>
            Ready to get
            <br />
            organized?
          </span>
          <span>
            Tools to
            <br />
            build your hub
          </span>
        </h2>
        <div className="flex overflow-x-scroll p-4 sm:p-10 sm:pb-6 bg-gray-light">
          {tools.nodes.map(({frontmatter, fields}) => (
            <div
              key={fields.slug}
              onClick={() => navigate(fields.slug)}
              className="group shrink-0 flex flex-col items-start justify-between mx-6 sm:mx-12 max-w-[60vw] sm:max-w-xs"
            >
              <GatsbyImage
                image={getImage(frontmatter.cover)}
                alt=""
                className="rotate-0 group-hover:rotate-3 flex-1"
                objectFit="contain"
              />
              <Link
                to={fields.slug}
                className="text-orange leading-tight mt-5 sm:w-1/2 text-lg"
              >
                {frontmatter.title}
              </Link>
            </div>
          ))}
        </div>
        <Link to="/tools" className="btn-orange mt-8">
          Get the tools
        </Link>
      </section>
      <section className="bg-white overflow-auto">
        <div className="max-w-4xl mx-auto mt-12 sm:mt-32">
          <div className="p-[56.25%_0_0_0] relative">
            {loadVideo ? (
              <iframe
                src="https://player.vimeo.com/video/473930165?title=0&autoplay=1&byline=0&portrait=0"
                className="absolute left-0 top-0 w-full h-full"
                title="Nothing is going back to normal by Inhabit"
                frameBorder="0"
                allow="fullscreen; autoplay"
                allowFullScreen
              ></iframe>
            ) : null}
            <div
              className={`w-full h-full absolute left-0 top-0 bg-orange ${
                loadVideo ? '-z-10' : ''
              }`}
            >
              <GatsbyImage
                image={video.childImageSharp.gatsbyImageData}
                alt="Title screen for the film 'Nothing is going back to normal'"
              />
              <button
                onClick={() => setLoadVideo(true)}
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              >
                <VisuallyHidden>Play video</VisuallyHidden>
                <Play className="stroke-white hover:fill-white stroke-[10] w-8 h-8 sm:w-24 sm:h-24" />
              </button>
            </div>
            <script src="https://player.vimeo.com/api/player.js" defer></script>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    introBg: file(relativePath: {eq: "crow.jpg"}) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    oliveBg: file(relativePath: {eq: "sky-olive.png"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    video: file(
      relativePath: {eq: "home/nothing-is-going-back-to-normal.png"}
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    tools: allMdx(
      filter: {slug: {glob: "en/tools/*"}}
      limit: 6
      sort: {fields: frontmatter___order}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          cover {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 320)
            }
          }
          title
        }
      }
    }
  }
`
